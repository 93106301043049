<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <!-- <b-button
            v-if="data !== null"
            variant="primary"
            @click="
              $router.push({
                name: 'invoice-add',
                params: { matter: data }
              })
            "
          >
            Add Invoice
          </b-button> -->
          <router-link
            v-if="data !== null"
            :to="{
              name: 'invoice-add',
              params: { matter: data }
            }"
            target="_blank"
            ><b-button class="ml-1" variant="primary"> Add Invoice </b-button>
          </router-link>
          <router-link
            v-else
            :to="{
              name: 'invoice-matter'
            }"
            target="_blank"
            ><b-button class="ml-1" variant="primary"> Add With Matter </b-button>
          </router-link>
          <!-- <b-button v-else variant="primary" :to="{ name: 'invoice-matter' }">
            Select Matter
          </b-button> -->
          <router-link
            :to="{
              name: 'invoice-add-blank'
            }"
            target="_blank"
            ><b-button class="ml-1" variant="success">
              Add Without Matter
            </b-button>
          </router-link>
        </b-col>
        <!-- Search -->
        <b-col cols="6" md="6">
          <div class="d-flex align-items-center justify-content-end">
            <b-input-group class="">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                debounce="500"
              />
            </b-input-group>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative"
    >
      <!-- Column: Id -->
      <template #cell(invoice_num)="data">
        <b-col class="p-0 text-nowrap">
          <p class="font-weight-bolder text-primary m-0">
            {{ data.value }}
          </p>
          <p class="font-weight-bold">
            {{ data.item.name }}
          </p>
        </b-col>
      </template>

      <template #cell(subject)="data">
        <b-col class="p-0">
          <p
            v-if="Object.keys(data.item).includes('unique_id')"
            class="font-weight-bolder text-primary m-0"
          >
            {{ data.item.unique_id }}
          </p>
          <p class="font-weight-bold">
            {{ data.value }}
          </p>
        </b-col>
      </template>

      <template #cell(products_total)="data">
        <p class="font-weight-bold text-nowrap">₹ {{ data.value }}</p>
      </template>

      <template #cell(created_at)="data">
        <p class="font-weight-bold text-nowrap">
          {{ getDate(data.value) }}
        </p>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="">
          <b-badge
            variant="primary"
            pill
            v-b-tooltip.hover
            title="Download Invoice"
            class="badge-round cursor-pointer mb-50 mr-50"
            @click="
              downloadInvoice({
                url: data.item.s3url,
                invoice_num: data.item.invoice_num,
                unique_id: data.item.unique_id
              })
            "
          >
            <feather-icon
              :id="`matter-${data.item._id}-preview-icon`"
              icon="DownloadIcon"
              size="16"
              class="mr-25"
            />
            <span>Download</span>
          </b-badge>

          <b-badge
            variant="danger"
            pill
            v-b-tooltip.hover
            title="Delete"
            class="badge-round cursor-pointer mr-1"
            @click="deleteAlert(data.item._id)"
          >
            <feather-icon
              :id="`matter-${data.item._id}-delete-icon`"
              icon="DeleteIcon"
              size="16"
              class="mr-25"
            />
            <span>Delete</span>
          </b-badge>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
            {{ dataMeta.of }} entries</span
          >
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalInvoices"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useInvoicesList from './useInvoiceList'
import moment from 'moment'
import axios from 'axios'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BInputGroup,
    BInputGroupPrepend,

    vSelect
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    matter: {
      default: null
    }
  },
  data() {
    return {
      data: this.matter
    }
  },
  methods: {
    getDate(date) {
      return moment(date).format('Do, MMMM YYYY')
    },
    downloadAs(url, name) {
      axios
        .get(url, {
          headers: {
            'Content-Type': 'application/octet-stream'
          },
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.setAttribute('download', name)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          URL.revokeObjectURL(url)
        })
        .catch((err) => {
          console.log('error', err)
        })
    },

    downloadInvoice({ url, invoice_num, unique_id }) {
      let href = url.replace(
        'http://s3.mymunshi.live',
        'https://app.mymunshi.in/exports'
      )
      if(unique_id){
        this.downloadAs(href, `${unique_id}-${invoice_num}.pdf`)
      }else{
        this.downloadAs(href, `${invoice_num}.pdf`)
      }
    },
    deleteAlert(_id) {
      console.log('ID' + _id)
      this.$swal({
        title: 'Warning',
        icon: 'warning',
        text: 'You really want to delete this invoice.',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('invoiceStore/deleteInvoice', {
              ctx: this,
              _id: _id
            })
            .then((result) => {
              this.refetchData()
            })
        }
      })
    }
  },
  setup(props) {
    var matterId = ''
    if (props.matter) {
      matterId = props.matter._id
    }
    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      refInvoiceListTable,
      refetchData
    } = useInvoicesList({ id: matterId })

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      refInvoiceListTable,
      refetchData
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
