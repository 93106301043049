<template>
  <invoice-list></invoice-list>
</template>

<script>
/* eslint-disable global-require */
import {} from 'bootstrap-vue'
import InvoiceList from './invoice-list/InvoiceList.vue'

export default {
  components: {
    InvoiceList
  }
}
</script>
